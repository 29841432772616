@tailwind base;
@tailwind components;
@tailwind utilities;

.text-spotify {
    color: #1DB954;
}

.bg-spotify {
    background-color: #1DB954;
}

.border-spotify {
    border-color: #1DB954;
}

.hover\:bg-spotify:hover {
    background-color: #1DB954;
}